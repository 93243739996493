import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Waypoint from 'react-waypoint'

import Layout from '../components/layout'
import Header from '../components/Header'
import Nav from '../components/Nav'
import mentoringImg from '../assets/images/mentoring.jpg'

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stickyNav: false,
    }
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }))
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }))
  }

  render() {
    return (
      <Layout>
        <Helmet title="SSO Mentoring App" />

        <Header />

        <Waypoint
          onEnter={this._handleWaypointEnter}
          onLeave={this._handleWaypointLeave}
        ></Waypoint>
        <Nav sticky={this.state.stickyNav} />

        <div id="main">
          <section id="intro" className="main">
            <div className="spotlight">
              <div className="content">
                <header className="major">
                  <h2>Track your mentoring journey</h2>
                </header>
                <p>
                  Sed lorem ipsum dolor sit amet nullam consequat feugiat
                  consequat magna adipiscing magna etiam amet veroeros. Lorem
                  ipsum dolor tempus sit cursus. Tempus nisl et nullam lorem
                  ipsum dolor sit amet aliquam.
                </p>
              </div>
              <span className="image">
                <img src={mentoringImg} alt="" />
              </span>
            </div>
          </section>

          <section id="second" className="main special">
            <header className="major">
              <h2>Features</h2>
              <p>
                Our mentor and mentee web apps come with a variety of simple,{' '}
                <br /> intuitive features to facilitate mentoring relationships.
              </p>
            </header>
            <ul className="statistics">
              <li className="style1">
                <span className="icon fa-bullseye"></span>
                <br />
                Set and review objectives
              </li>
              <li className="style2">
                <span className="icon fa-calendar"></span>
                <br />
                Schedule upcoming sessions
              </li>
              <li className="style3">
                <span className="icon fa-laptop"></span>
                <br />
                Manage multiple relationships
              </li>
              <li className="style4">
                <span className="icon fa-envelope"></span>
                <br />
                Easily invite new mentees
              </li>
              <li className="style5">
                <span className="icon fa-signal"></span>
                <br />
                Track programme impact
              </li>
            </ul>
          </section>

          <section id="cta" className="main special">
            <header className="major">
              <h2>Find out more</h2>
              <p>Get in touch to register interest for your organisation.</p>
            </header>
            <footer className="major">
              <ul className="actions">
                <li>
                  <Link to="#" className="button">
                    Email us
                  </Link>
                </li>
              </ul>
            </footer>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Index
