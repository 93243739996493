import React from 'react'
import styled from 'styled-components'
import { FaSeedling } from 'react-icons/fa'
import { Link } from 'gatsby'

const HeaderIcon = styled(FaSeedling)`
  height: 80px;
  width: 80px;
  margin-bottom: 10px;
  color: #fcecc9;
`

const Header = props => (
  <>
    <header id="header" className="alt">
      <HeaderIcon />
      <h1>SSO Mentoring App</h1>
    </header>
    <section id="first" className="main special">
      <ul className="features">
        <li>
          <span className="icon major style1 fa-users"></span>
          <h3>
            <strong>Mentee Login</strong>
          </h3>
        </li>
        <li>
          <span className="icon major style3 fa-graduation-cap"></span>
          <h3>
            <strong>Mentor Login</strong>
          </h3>
        </li>
        <li>
          <span className="icon major style5 fa-cog"></span>
          <h3>
            <strong>Admin Login</strong>
          </h3>
        </li>
      </ul>
    </section>
  </>
)

export default Header
